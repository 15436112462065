import { ActionReducerMap, MetaReducer, State, ActionReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromSettings from './settings/settings.reducer';
import * as fromSession from './session/session.reducer';
import { SessionEffects } from './session/session.effects';
import { SettingsEffects } from './settings/settings.effects';
import { reduce, merge, pluck, map } from 'rxjs/operators';
import { of } from 'rxjs';
//import * as SecureLS from 'secure-ls';

export interface AppState {
        settings: fromSettings.SettingsState;
        session: fromSession.SessionState;
}

export const initialState: AppState = {
        settings: fromSettings.initialState,
        session: fromSession.initialState
};

export const reducers: ActionReducerMap<AppState> = {
        settings: fromSettings.reducer,
        session: fromSession.reducer
};

export const effects = [SessionEffects, SettingsEffects];

function setSavedState(state: any, lsKey: string) {
        //var ls = new SecureLS({ encodingType: 'aes' });
        //ls.set(lsKey, JSON.stringify(state));
        try {
                localStorage.setItem(lsKey, JSON.stringify(state));
        } catch (error) {
                console.log(error.message);
        }
}
function getSavedState(lsKey: string): any {
        //var ls = new SecureLS({ encodingType: 'aes' });
        //const rtnState = ls.get(lsKey);
        const rtnState = localStorage.getItem(lsKey);
        if (rtnState != null) {
                return JSON.parse(rtnState);
        }
        else {
                return null;
        }
}

// the keys from state which we'd like to save.
const stateKeys = ['session'];
// the key for the local storage.
const localStorageKey = '_jabrly_';

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
        return (state, action) => {
                console.log('state', state);
                console.log('action', action);

                return reducer(state, action);
        };
}
export function localStore(reducer: ActionReducer<any>): ActionReducer<any> {
        let onInit = true; // after load/refresh…
        return (state, action) => {
                // reduce the nextState.
                const nextState = reducer(state, action);
                // init the application state.
                if (onInit) {
                        onInit = false;
                        const savedState = getSavedState(localStorageKey);
                        console.log('Saved State', savedState);
                        if (savedState != null) {
                                return {...nextState, ...savedState};
                        }
                        else {
                                return nextState;
                        }
                }
                // save the next state to the application storage.
                const stateToSave = stateKeys.reduce((obj, key) => ({ ...obj, [key]: nextState[key] }), {});
                setSavedState(stateToSave, localStorageKey);
                return nextState;

        };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [debug, localStore] : [localStore];

