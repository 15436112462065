import { first, zipAll } from "rxjs/operators";
import { ContactEmail } from "./../../classes/lead/lead";
import { History } from "src/app/classes/conversation/history";
import { Lead } from "src/app/classes/lead/lead";
import { createReducer, on, Action, State } from "@ngrx/store";
import * as fromSession from "./session.actions";
import { User } from "src/app/classes/user/user";
import { Pagedata } from "src/app/classes/pagedata/pagedata";
import { Stats } from "src/app/classes/stats/stats";
import { Observable, merge } from "rxjs";
import { Conversation } from "src/app/classes/conversation/conversation";
import { Chat } from "src/app/classes/chat/chat";
import { Campaign } from "src/app/classes/campaign/campaign";
import { Huntgroup } from "src/app/classes/hungroup/huntgroup";
import { ScheduleEvent } from "src/app/classes/schedule/schedule-event";
import { CalendarView } from "angular-calendar";

export interface SessionState {
  currentUser: User;
  currentPage: Pagedata;
  stats: Stats;
  leads: Array<Lead>;
  pipeline: Array<Lead>;
  schedule: Array<ScheduleEvent>;
  chats: Array<Chat>;
  users: Array<User>;
  campaigns: Array<Campaign>;
  huntgroups: Array<Huntgroup>;
  displayedLead: Lead;
  displayLeadTab: string;
  attentionFilter: boolean;
  campaignFilter: Array<string>;
  calendarMode: CalendarView | "month" | "day" | "week";
  unclaimedFilter: boolean;
  searchBarVisible: boolean;
}

export const initialState: SessionState = {
  currentUser: null,
  currentPage: {
    displayback: false,
    title: "",
    backUrl: "/app/dashboard",
    displayfilter: false,
    displaycalendar: false,
  },
  stats: {
    active: 0,
    received: 0,
    attention: 0,
    closed: 0,
    pipeline: 0,
    dispositioned: [],
    won: 0,
    messages: 0,
    calls: 0,
    facebook: 0,
    leadflow: [],
    activity: [],
  },
  leads: new Array<Lead>(),
  pipeline: new Array<Lead>(),
  schedule: new Array<ScheduleEvent>(),
  chats: new Array<Chat>(),
  users: new Array<User>(),
  campaigns: new Array<Campaign>(),
  huntgroups: new Array<Huntgroup>(),
  displayedLead: null,
  displayLeadTab: "notes",
  attentionFilter: false,
  campaignFilter: new Array<string>(),
  calendarMode: "month",
  unclaimedFilter: false,
  searchBarVisible: false,
};

export const SessionReducer = createReducer(
  initialState,
  on(fromSession.AuthUserSuccess, (state, { user }) => ({
    ...state,
    currentUser: user,
  })),
  on(fromSession.LogoutUser, (state) => ({
    ...state,
    leads: [],
    pipeline: [],
    schedule: [],
    chats: [],
    stats: {active: 0,
      received: 0,
      attention: 0,
      closed: 0,
      pipeline: 0,
      dispositioned: [],
      won: 0,
      messages: 0,
      calls: 0,
      facebook: 0,
      leadflow: [],
      activity: [],},
    users: [],
    campaigns: [],
    huntgroups: [],
    currentUser: null,
    displayedLead: null,
    displayLeadTab: "notes",
  })),
  on(fromSession.GetUsersSuccess, (state, { users }) => ({
    ...state,
    users,
  })),
  on(fromSession.UpdateUserSuccess, (state, { user}) => ({
    ...state,
    currentUser: user
  })),
  on(fromSession.UpdateUserPhotoSuccess, (state, { b64_photo}) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      photo: b64_photo,
    }
  })),
  on(fromSession.GetCampaignsSuccess, (state, { campaigns }) => ({
    ...state,
    campaigns,
  })),
  on(fromSession.GetHuntgroupsSuccess, (state, { huntgroups }) => ({
    ...state,
    huntgroups,
  })),
  on(fromSession.DisplayLead, (state, { lead }) => ({
    ...state,
    displayedLead: lead,
  })),
  on(fromSession.DisplayLeadTab, (state, { tab }) => ({
    ...state,
    displayLeadTab: tab,
  })),
  on(fromSession.UpdateAttentionFilter, (state, { newValue }) => ({
    ...state,
    attentionFilter: newValue,
  })),
  on(fromSession.UpdateCampaignFilter, (state, { filter }) => ({
    ...state,
    campaignFilter: filter,
  })),
  on(fromSession.UpdateCalendarMode, (state, { mode }) => ({
    ...state,
    calendarMode: mode,
  })),
  on(fromSession.UpdateUnclaimedFilter, (state, { newValue }) => ({
    ...state,
    unclaimedFilter: newValue,
  })),
  on(fromSession.UpdateSearchBarVisible, (state, { newValue }) => ({
    ...state,
    searchBarVisible: newValue,
  })),
  on(fromSession.ChangeTopBar, (state, { pagedata }) => ({
    ...state,
    currentPage: pagedata,
  })),
  on(fromSession.GetStatsSuccess, (state, { stats }) => ({
    ...state,
    stats,
  })),
  on(fromSession.GetChatsSuccess, (state, { chats }) => ({
    ...state,
    chats,
  })),
  on(fromSession.GetScheduleSuccess, (state, { schedule }) => ({
    ...state,
    schedule,
  })),
  on(fromSession.GetLeadsSuccess, (state, { leads }) => ({
    ...state,
    leads: leads.map((l) => {
      const tmp = state.leads?.find(({ id }) => id === l.id);
      if (tmp != null) {
        return {
          ...l,
          conversation: tmp.conversation,
          contactEmail: tmp.contactEmail,
          contactPhone: tmp.contactPhone,
        };
      } else {
        return l;
      }
    }),
    stats: {
      ...state.stats,
      active: leads.length,
    },
  })),
  on(fromSession.GetPipelineSuccess, (state, { leads }) => ({
    ...state,
    pipeline: leads.map((l) => {
      const tmp = state.pipeline?.find(({ id }) => id === l.id);
      if (tmp != null) {
        return {
          ...l,
          conversation: tmp.conversation,
          contactEmail: tmp.contactEmail,
          contactPhone: tmp.contactPhone,
        };
      } else {
        return l;
      }
    }),
    stats: {
      ...state.stats,
      pipeline: leads.length,
    },
  })),
  on(fromSession.UpdateLead, (state, { lead, email, phone, address }) => ({
    ...state,
    leads: state.leads.map((l) => {
      // const tmp = state.leads?.find(({ id }) => id === l.id);
      if (l.id === lead.id) {
        return {
          ...l,
          first_name: lead.first_name,
          last_name: lead.last_name,
          attention: lead.attention,
          contactEmail: email,
          contactPhone: phone,
          contactAddress: address,
        };
      } else {
        return l;
      }
    }),
    pipeline: state.pipeline.map((l) => {
      // const tmp = state.pipeline?.find(({ id }) => id === l.id);
      if (l.id === lead.id) {
        return {
          ...l,
          first_name: lead.first_name,
          last_name: lead.last_name,
          attention: lead.attention,
          contactEmail: email,
          contactPhone: phone,
          contactAddress: address,
        };
      } else {
        return l;
      }
    }),
  })),
  on(fromSession.UpdateLeadSchedule, (state, { lead, date }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          scheduled: date,
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          scheduled: date,
        };
      }
      return l;
    }),
  })),
  on(
    fromSession.UpdateLeadCloseProbability,
    (state, { lead, probability }) => ({
      ...state,
      leads: state.leads.map((l) => {
        if (lead.id === l.id) {
          return {
            ...l,
            probability,
          };
        }
        return l;
      }),
      pipeline: state.pipeline.map((l) => {
        if (lead.id === l.id) {
          return {
            ...l,
            probability,
          };
        }
        return l;
      }),
    })
  ),
  on(fromSession.UpdateLeadExpectedCloseDate, (state, { lead, date }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          expected_close_date: date,
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          expected_close_date: date,
        };
      }
      return l;
    }),
  })),
  on(fromSession.CallLeadSuccess, (state, { lead }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          attention: false,
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          attention: false,
        };
      }
      return l;
    }),
  })),
  on(fromSession.CloseLeadSuccess, (state, { lead }) => ({
    ...state,
    leads: state.leads.filter((l) => l.id !== lead.id),
    pipeline: state.pipeline.filter((l) => l.id !== lead.id),
  })),
  on(fromSession.AssignLeadSuccess, (state, { lead, user }) => ({
    ...state,
    leads: state.leads.filter((l) => l.id !== lead.id),
    pipeline: state.pipeline
      .filter((l) => l.id !== lead.id)
      .concat(user.id === state.currentUser.id ? [lead] : []),
  })),
  on(fromSession.AssignChatSuccess, (state, { chat, user }) => ({
    ...state,
    chats: state.chats.map((c) => {
      if (chat.id === c.id) {
        return {
          ...c,
          assigned_user_id: user.id,
        };
      }
      return c;
    }),
  })),
  on(fromSession.lazyLoadChatMessagesSuccess, (state, { chat, messages }) => ({
    ...state,
    chats: state.chats.map((c) => {
      if (chat.id === c.id) {
        return {
          ...c,
          messages,
        };
      }
      return c;
    }),
  })),
  on(fromSession.lazyLoadConversationSuccess, (state, { lead, convo }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          conversation: convo,
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          conversation: convo,
        };
      }
      return l;
    }),
  })),
  on(
    fromSession.LazyLoadLeadDetailSuccess,
    (state, { lead, phone, email, address }) => ({
      ...state,
      leads: state.leads.map((l) => {
        if (lead.id === l.id) {
          return {
            ...l,
            contactPhone: phone,
            contactEmail: email,
            contactAddress: address,
          };
        }
        return l;
      }),
      pipeline: state.pipeline.map((l) => {
        if (lead.id === l.id) {
          return {
            ...l,
            contactPhone: phone,
            contactEmail: email,
            contactAddress: address,
          };
        }
        return l;
      }),
    })
  ),
  on(fromSession.NewTextMessage, (state, { message }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (message.conversation_id === l.conversation.id) {
        return {
          ...l,
          attention: message.message_direction === "IN",
          conversation: {
            ...l.conversation,
            messages: [...l.conversation.messages, message],
            history: [
              ...l.conversation.history,
              new History({
                id: message.id,
                date_created: message.date_created,
                direction: message.message_direction,
                type: "MESSAGE",
                detail: "",
              }),
            ],
          },
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (message.conversation_id === l.conversation.id) {
        return {
          ...l,
          attention: message.message_direction === "IN",
          conversation: {
            ...l.conversation,
            messages: [...l.conversation.messages, message],
            history: [
              ...l.conversation.history,
              new History({
                id: message.id,
                date_created: message.date_created,
                direction: message.message_direction,
                type: "MESSAGE",
                detail: "",
              }),
            ],
          },
        };
      }
      return l;
    }),
  })),
  on(fromSession.NewChatMessage, (state, { message }) => ({
    ...state,
    chats: state.chats.map((c) => {
      if (message.chat_id === c.id) {
        return {
          ...c,
          messages: [...c.messages, message],
        };
      }
      return c;
    }),
  })),
  on(fromSession.UpdateConversationNote, (state, { lead, note }) => ({
    ...state,
    leads: state.leads.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          conversation: {
            ...l.conversation,
            notes: note,
          },
        };
      }
      return l;
    }),
    pipeline: state.pipeline.map((l) => {
      if (lead.id === l.id) {
        return {
          ...l,
          conversation: {
            ...l.conversation,
            notes: note,
          },
        };
      }
      return l;
    }),
  }))
);

export function reducer(state: SessionState | undefined, action: Action) {
  return SessionReducer(state, action);
}
